/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            email
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/profile-pic.jpg"
        width={120}
        height={120}
        quality={95}
        alt="Profile picture"
      />
      {author?.name && (
        <p>
          <span>
            Written by <strong>{author.name}</strong>
            <br />
            <small>{author?.summary || null}</small>
            <br />
            {` `}
          </span>
          {social && social.email ? (
            <a href={`mailto:${social.email}`}>Say hello</a>
          ) : null}
        </p>
      )}
    </div>
  )
}

export default Bio
